
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import Item from '../../Item/Item.vue';
import VLazyImage from 'v-lazy-image/v2';
import { UserAccount, UserProfile } from '@/types/User';
import Avatar from '@/components/Avatar.vue';

@Component({components: {Avatar, Item, Button, VLazyImage}})
export default class ProfileInfo extends Vue {
  @Prop({required: false}) user: UserAccount | UserProfile | undefined;
  @Prop({default: false}) public: boolean;

  get uuid() {
    if (!this.user) return '';
    return this.user.uuid || '';
  }

  get providerLink() {
    switch (this.user.provider) {
      case 'steam':
        return 'https://steamcommunity.com/profiles/' + this.user.providerId;
      case 'vkontakte':
        return 'https://vk.com/id' + this.user.providerId;
    }
  }

  copyUserID() {
    navigator.clipboard.writeText(this.user.uuid);
    this.$notify({
      group: 'notification',
      duration: 5500,
      type: 'success',
      title: `Профиль`,
      text: 'Ваш ID скопирован в буфер обмена'
    });
  }

  openRefill() {
    this.$store.dispatch('modal/toggleModal', {
      name: 'refill'
    });
  }
}
