
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserDrop } from '@/types/User';

@Component({})
export default class ProfileItemStatusHover extends Vue {
  @Prop({type: Object, required: false}) drop: UserDrop;
  now: number = Math.trunc((new Date()).getTime() / 1000);
  timerInterval: any;

  mounted() {
    this.startIntervalTimer();
  }

  beforeDestroy() {
    this.clearIntervalTimer();
  }

  @Watch('drop.offer')
  onDropOfferChange() {
    this.startIntervalTimer();
  }


  get tradeStatusImage() {
    return require(`@/assets/img/loader-${this.tradeStatusClass}-40.svg`);
  }

  get tradeStatusMessage() {
    switch (true) {
      case this.drop.offer.type === 'money' && this.drop.offer.status < 2:
        return 'Пополняем Steam';
      case this.drop.offer.type === 'item' && !this.drop.offer.trade:
        return 'Ожидаем продавца';
    }
  }

  get tradeStatusClass() {
    switch (true) {
      case this.drop.offer.type === 'money' && this.drop.offer.status < 2:
        return 'green';
      case this.drop.offer.type === 'item' && !this.drop.offer.trade:
        return 'blue';
      case this.drop.offer.type === 'item' && !!this.drop.offer.trade:
        return 'change';
    }
  }

  get tradeOfferLink() {
    return `https://steamcommunity.com/tradeoffer/${this.drop.offer.trade}`;
  }

  get tradeTimeDifference() {
    if (!this.drop.offer.sentAt) return null;


    const tradeValidTill = Math.trunc(Date.parse(this.drop.offer.sentAt) / 1000) + 5 * 60;
    return tradeValidTill - this.now;
  }

  get tradeOfferAcceptTime() {
    if (!this.tradeTimeDifference) return null;

    const format = (num: number) => num.toString().length === 1 ? `0${num}` : num;

    const seconds = this.tradeTimeDifference <= 0 ? '00' : format(this.tradeTimeDifference % 60);
    const minutes = this.tradeTimeDifference <= 0 ? '00' : format(Math.trunc(this.tradeTimeDifference / 60) % 60);

    return `${minutes}:${seconds}`;
  }


  startIntervalTimer() {
    if (this.drop.offer.status < 2 && this.drop.offer.sentAt) {
      this.timerInterval = setInterval(() => {
        this.now = Math.trunc((new Date()).getTime() / 1000);
        if (this.tradeTimeDifference <= 0) {
          this.clearIntervalTimer();
        }
      }, 1000);
    }
  }


  clearIntervalTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
}
