
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import saveTradeLink from '@/requests/User/saveTradeLink/saveTradeLink';
import saveLoginSteam from '@/requests/User/saveLoginSteam/saveLoginSteam';

@Component({components: {Button}})
export default class ProfileInputSetting extends Vue {
  @Prop({default: 'trade'}) type: 'trade' | 'steam';

  inputData: string = '';

  help: { content: string, type: 'trade' | 'steam' }[] = [
    {
      content: 'У каждого пользователя Steam есть уникальная ссылка на обмен. Добавьте ее в поле ввода, чтобы мы могли отправлять вам выбитые предметы',
      type: 'trade'
    },
    {
      content: 'Зачисление баланса на Steam происходит по логину вашего аккаунта. Внимание: Логин это не никнейм. Нажмите “Узнать” для получения подробной информации',
      type: 'steam'
    }
  ];
  isHelpVisible: boolean = false;

  mounted() {
    this.inputData = (this.type === 'trade' ? this.user.tradeLink : this.user.loginSteam) || '';
  }

  get user() {
    return this.$store.getters['user/isAuthorized'] ? this.$store.state.user : null;
  }

  get getHelp() {
    return this.help.filter((el) => {
      return el.type === this.type;
    })[0].content;
  }

  moreInfo() {
    if (this.type === 'trade') {
      return window.open('https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url', '_blank');
    }

    return window.open('https://store.steampowered.com/account', '_blank');
  }

  async saveTradeLink() {
    try {
      const {error, user} = await saveTradeLink(this.inputData);

      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'error',
          title: 'Сохранение трейд-ссылки',
          text: error
        });
      }

      this.$store.commit('user/update', user);
      this.inputData = user.tradeLink;

      this.$notify({
        group: 'notification',
          duration: 5500,
        type: 'success',
        title: 'Сохранение трейд-ссылки',
        text: 'Трейд-ссылка сохранена!'
      });
    } catch (e) {
      console.log(e);
      this.$notify({
        group: 'notification',
          duration: 5500,
        type: 'error',
        title: 'Сохранение трейд-ссылки',
        text: 'Неизвестная ошибка!'
      });
    }
  }

  async saveSteamLogin() {
    try {
      const {error, user} = await saveLoginSteam(this.inputData);
      if (error)
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Сохранение логина',
          text: error
        });

      this.$store.commit('user/update', user);
      this.inputData = user.loginSteam;

      this.$notify({
        group: 'notification',
          duration: 5500,
        type: 'success',
        title: 'Сохранение логина',
        text: 'Логин Steam успешно сохранён!'
      });
    } catch (e) {
      console.log(e);
      this.$notify({
        group: 'notification',
          duration: 5500,
        type: 'warning',
        title: 'Сохранение логина',
        text: 'Неизвестная ошибка!'
      });
    }
  }
}
