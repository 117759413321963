
import { Component, Prop, Vue } from 'vue-property-decorator';
import EmptyInventoryButtons from '@/components/DropInventory/EmptyInventory/EmptyInventoryButtons.vue';

@Component({
  components: {
    EmptyInventoryButtons
  }
})
export default class EmptyInventory extends Vue {

  @Prop({type: String, default: 'profile'}) place: string;

  get user() {
    return this.$store.getters['user/isAuthorized'] ? this.$store.state.user : null;
  }

}
