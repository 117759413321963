
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserDrop } from '@/types/User';
import withdrawItem from '@/requests/Withdraw/withdrawItem/withdrawItem';
import { getDropSellPrice } from '@/helpers/getDropSellPrice';
import DropInventory from '@/components/DropInventory/DropInventory.vue';
import sellItem from '@/requests/Withdraw/sellItem/sellItem';

@Component({})
export default class ProfileItemWithdrawHover extends Vue {
  @Prop({ type: Object, required: true }) drop: UserDrop;
  @Prop({ type: Object, required: true }) inventoryRef: DropInventory;

  private blockRequest: boolean = false;

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get sellPrice() {
    return getDropSellPrice(this.drop.item.price);
  }

  async sellDrop() {
    if (!this.user || this.blockRequest) return;
    this.blockRequest = true;

    try {
      const { user, error } = await sellItem(this.drop.id);
      if (error)
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Инвентарь',
          text: error,
        });

      this.$store.commit('user/update', user);
      this.inventoryRef.removeDrop(this.drop.id);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Предмет успешно продан!',
      });
    } catch (e) {
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Инвентарь',
        text: 'Неизвестная ошибка!',
      });
    } finally {
      this.blockRequest = false;
    }
  }

  async withdrawDrop() {
    if (this.blockRequest || !this.user) return;
    this.blockRequest = true;

    try {
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Пробуем вывести ваш предмет...',
      });

      const { error, replaceItems } = await withdrawItem(
        this.drop.id,
        this.drop.item.type
      );

      if (replaceItems) {
        this.$store.dispatch('modal/toggleModal', {
          name: 'replaceItem',
          data: { replaceItems, dropItem: this.drop, ref: this.inventoryRef },
        });

        if (!error) {
          return;
        }
      }

      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Инвентарь',
          text: error,
        });
      }

      this.inventoryRef.proceedDropToPendingWithdraw(
        this.drop.id,
        this.drop.item.type
      );
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text:
          this.drop.item.type !== 'money'
            ? 'Совсем скоро Вы получите свой предмет! Проверяйте свои обмены почаще! Если Вы не примите обмен, то помочь мы не сможем!'
            : 'Запрос на вывод стоимости предмета на Ваш баланс Steam отправлен!',
      });
    } catch (e) {
      if (e.isAxiosError && e.response.status === 429) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'error',
          title: 'Инвентарь',
          text: 'Слишком частые запросы! Попробуйте через минуту!',
        });
      }

      console.error(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Инвентарь',
        text:
          e.response?.status === 501
            ? e.response.data.error
            : 'Попробуйте позже!',
      });
    } finally {
      this.blockRequest = false;
    }
  }
}
