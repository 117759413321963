
import { Component, Prop, Vue } from 'vue-property-decorator';
import PadButton from '@/components/Buttons/PadButton.vue';
import EmptyInventoryButtons from '@/components/DropInventory/EmptyInventory/EmptyInventoryButtons.vue';
import { DropSourceSelect } from '@/components/DropInventory/DropInventory.vue';

@Component({
  components: { EmptyInventoryButtons, PadButton },
})
export default class DropSourceSwitcher extends Vue {
  @Prop({ type: String, required: true }) selectedDropSource: DropSourceSelect;
  @Prop({ type: Function, required: true }) setSelectedDropSource: (
    source: DropSourceSelect
  ) => void;
  @Prop({ type: Boolean, default: false }) showFilters: boolean;

  selectSource(source: DropSourceSelect) {
    if (this.showFilters) {
      this.setSelectedDropSource(source);
    }
  }
}
