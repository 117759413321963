
import { Component, Prop, Vue } from 'vue-property-decorator';
import Item from '@/components/Item/Item.vue';
import ProfileBestItem from '@/components/Profile/ProfileDashboard/ProfileBestItem.vue';
import Button from '@/components/Buttons/Button.vue';
import CaseImage from '@/components/Case/CaseImage.vue';
import ProfileOverview from '@/components/Profile/ProfileDashboard/ProfileInfo.vue';
import ProfileInputSetting from '@/components/Profile/ProfileDashboard/ProfileInputSetting.vue';
import { UserAccount, UserProfile } from '@/types/User';

@Component({
  components: {
    ProfileOverview,
    Button,
    CaseImage,
    Item,
    ProfileBestItem,
    ProfileInputSetting,
  },
})
export default class ProfileDashboard extends Vue {
  @Prop({ required: false }) user?: UserProfile | UserAccount;

  get isPublicProfile() {
    return !this.user || !this.user.hasOwnProperty('tradeLink');
  }
}
