export function numFormat(num: number) {
  const decimals = 0;
  let thousands_sep = ' ';
  let dec_point = '';
  let exponent = '';
  const numberstr = num.toString();
  const eindex = numberstr.indexOf('e');
  let i, z;

  if (eindex > -1) {
      exponent = numberstr.substring(eindex);
      num = parseFloat(numberstr.substring(0, eindex));
  }

  if (decimals != null) {
      const temp = Math.pow(10, decimals);
      num = Math.round(num * temp) / temp;
  }
  const sign = num < 0 ? '-' : '';
  let integer = (num > 0 ? Math.floor(num) : Math.abs(Math.ceil(num))).toString();

  let fractional = num.toString().substring(integer.length + sign.length);
  dec_point = dec_point != null ? dec_point : '.';
  fractional = decimals != null && decimals > 0 || fractional.length > 1 ? (dec_point + fractional.substring (1)) : '';

  if (decimals != null && decimals > 0) {
      for (i = fractional.length - 1, z = decimals; i < z; ++i)
      fractional += '0';
  }

  thousands_sep = (thousands_sep != dec_point || fractional.length == 0) ? thousands_sep : null;
  if (thousands_sep != null && thousands_sep != '') {
      for (i = integer.length - 3; i > 0; i -= 3)
      integer = integer.substring (0 , i) + thousands_sep + integer.substring (i);
  }
  return sign + integer + fractional + exponent;
}