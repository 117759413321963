
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserDrop } from '@/types/User';
import CaseImage from '@/components/Case/CaseImage.vue';
import { getItemDropSourceLink } from '@/helpers/getItemDropSourceLink';

@Component({
  components: {CaseImage}
})
export default class ItemSource extends Vue {
  @Prop({type: Object, default: () => null}) drop: UserDrop;
  @Prop({type: Boolean, default: false}) hideIcon: boolean;

  get actionLink() {
    return getItemDropSourceLink(this.drop);
  }
}
