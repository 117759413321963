
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import Item from '../../Item/Item.vue';
import { Case } from '@/types/Case';
import ItemSource from '@/components/Item/ItemSource.vue';
import CaseImage from '@/components/Case/CaseImage.vue';
import { UserDrop } from '@/types/User';

@Component({ components: { Item, Button, CaseImage, ItemSource } })
export default class ProfileBestItem extends Vue {
  @Prop({ default: 'case' }) type: 'case' | 'drop';
  @Prop({ default: null }) data: Partial<Case> | Partial<UserDrop> | null;

  goToCase() {
    if (this.data) this.$router.push('/case/' + this.data.id);
  }
}
