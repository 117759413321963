
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import { numFormat } from '@/helpers/numFormat';

@Component({ components: { Button } })
export default class ProfileBanner extends Vue {
  @Prop() sum: number;
  @Prop() mana?: number;

  numFormat(num: number) {
    return numFormat(num);
  }
}
