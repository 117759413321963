var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"drops-menu-wrap"},[_c('div',{staticClass:"drops-menu"},[_c('div',{class:{
        'drops-menu__item': true,
        '--active': _vm.selectedDropSource === 'all',
      },on:{"click":function($event){return _vm.selectSource('all')}}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/case-2.svg')}}),_vm._v(" Предметы ")],1),(_vm.showFilters)?_c('div',{class:{
        'drops-menu__item': true,
        '--active': _vm.selectedDropSource === 'contract',
      },on:{"click":function($event){return _vm.selectSource('contract')}}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/contracts.svg')}}),_vm._v(" Контракты ")],1):_vm._e(),(_vm.showFilters)?_c('div',{class:{
        'drops-menu__item': true,
        '--active': _vm.selectedDropSource === 'upgrade',
        '--disabled': true,
      },on:{"click":function($event){return _vm.selectSource('upgrade')}}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/upgrades.svg')}}),_vm._v(" Апгрейды ")],1):_vm._e(),(_vm.showFilters)?_c('div',{class:{
        'drops-menu__item': true,
        '--active': _vm.selectedDropSource === 'gift',
        '--gift': true,
      },on:{"click":function($event){return _vm.selectSource('gift')}}},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/live-bar-gift.svg')}}),_vm._v(" Подарки ")],1):_vm._e(),_c('div',{staticClass:"_blank"}),_c('div',{staticClass:"_blank"}),_c('div',{staticClass:"_blank"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }