
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';

import EmptyInventory from '@/components/DropInventory/EmptyInventory/EmptyInventory.vue';
import Head from '@/components/Profile/ProfileDashboard/ProfileDashboard.vue';
import ProfileDashboard from '@/components/Profile/ProfileDashboard/ProfileDashboard.vue';
import ProfileBanner from '@/components/Profile/ProfileBanner.vue';
import ProfileInfo from '@/components/Profile/ProfileDashboard/ProfileInfo.vue';
import DropInventory from '@/components/DropInventory/DropInventory.vue';
import ProfileItemStatusHover from '@/components/Profile/ProfileItemStatusHover.vue';
import ProfileItemWithdrawHover from '@/components/Profile/ProfileItemWithdrawHover.vue';
import LoadingScreen from '@/components/Layout/LoadingScreen.vue';
import DropSourceSwitcher from '@/components/DropInventory/InventoryHeader/DropSourceSwitcher.vue';
import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue';

@Component({
  components: {
    DropSourceSwitcher,
    LoadingScreen,
    ProfileItemWithdrawHover,
    ProfileItemStatusHover,
    DropInventory,
    ProfileDashboard,
    Head,
    ProfileBanner,
    ProfileInfo,
    EmptyInventory,
    Breadcrumbs,
  },
})
export default class Account extends Vue {
  @Ref('dropInventory') readonly dropInventory: DropInventory;

  created() {
    this.$setPageTitle('Аккаунт | DOTALOOT');
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  @Watch('user')
  onUserChange() {
    if (!this.user) this.$router.push('/');
  }
}
