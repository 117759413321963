import Axios from 'axios';
import { UserAccount } from '@/types/User';
import { MOCK_SAVE_TRADE_LINK } from '@/requests/User/saveTradeLink/mockSaveTradeLink';

export interface SaveTradeLinkResponse {
    error?: string;
    user?: Partial<UserAccount>;
}

export default async function saveTradeLink(tradeLink: string): Promise<SaveTradeLinkResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_SAVE_TRADE_LINK;
    }

    return (await Axios.post<SaveTradeLinkResponse>('/api/user/trade', {tradeLink})).data;
}
