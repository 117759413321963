import Axios from 'axios';
import { Item } from '@/types/Item';
import { MOCK_WITHDRAW_ITEM } from '@/requests/Withdraw/withdrawItem/mockWithdrawItem';

export interface WithdrawItemResponse {
    error?: string;
    replaceItems?: Item[];
}

export default async function withdrawItem(dropId: number, itemType: string): Promise<WithdrawItemResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_WITHDRAW_ITEM;
    }

    const response = await Axios.post(itemType !== 'money' ? '/api/drop/withdraw' : '/api/drop/to_steam', {
        id: dropId
    });

    const {error, similarItems} = response.data;

    return {error: error ? error.error || error : undefined, replaceItems: similarItems};
}
