import Axios from 'axios';
import { UserAccount } from '@/types/User';
import { MOCK_SAVE_LOGIN_STEAM } from '@/requests/User/saveLoginSteam/mockSaveLoginSteam';

export interface SaveLoginSteamResponse {
    error?: string;
    user?: Partial<UserAccount>;
}

export default async function saveLoginSteam(loginSteam: string): Promise<SaveLoginSteamResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_SAVE_LOGIN_STEAM;
    }

    return (await Axios.post<SaveLoginSteamResponse>('/api/user/login-steam', {loginSteam})).data;
}
